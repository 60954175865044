var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CONTACTS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Contacts' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-circle-08"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.CONTACTS")))])])]}}],null,false,405653905)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CUSTOMERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Customers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-circle-08"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.CUSTOMERS")))])])]}}],null,false,4145606869)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUPPLIERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Suppliers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-circle-08"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SUPPLIERS")))])])]}}],null,false,508506117)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }